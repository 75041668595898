const Q = require(`q`);
const _ = require(`underscore`);
const moment = require(`moment`);
const __request = require(`./__request`);

// 接口文档地址：
// http://192.168.0.15:8080/doc.html#/所有接口/wallet-account-controller/queryAccountDetailUsingPOST
// http://192.168.0.15:9988/swagger-ui.html#/wallet-account-controller/queryAccountFlowUsingPOST
const wallet_account_queryAccountFlow = (pParameter) => {
  if (!pParameter) pParameter = {};
  const currentPage = pParameter.currentPage || 1;
  const flowType = pParameter.flowType || 0;
  const pageSize = pParameter.pageSize || 10;
  const tradingHourEnd = pParameter.tradingHourEnd || ``;
  const tradingHourStart = pParameter.tradingHourStart || ``;
  const p = {
    method: 'post',
    urlSuffix: '/wallet/account/queryAccountFlow',
    data: {
      currentPage,
      flowType,
      pageSize,
      tradingHourEnd,
      tradingHourStart,
    }
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      const pageResult = data.pageResult;
      if (!pageResult) {
        const msg = 'wallet_account_queryAccountFlow parameter error, code=101';
        console.error(msg);
        return Q.reject({ msg: msg });
      }
      const pageData = pageResult.pageData;
      // if (!_.isArray(pageData)) {
      //   const msg = 'wallet_account_queryAccountFlow parameter error, code=102';
      //   console.error(msg);
      //   return Q.reject({msg: msg});
      // }

      _.each(pageData, function (m) {
        m.businessTypeText_001 = ``;
        if (m.businessTypeText) {
          if (m.businessTypeText.length > 0) {
            m.businessTypeText_001 = m.businessTypeText[0];
          }
        }

        m.flowTypeText_001 = ``;
        if (m.flowTypeText) {
          if (m.flowTypeText.length > 0) {
            m.flowTypeText_001 = m.flowTypeText[0];
          }
        }

      });

      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = wallet_account_queryAccountFlow;
