import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import wallet_account_queryFlowTypeEnum from '@/lib/data-service/default/wallet_account_queryFlowTypeEnum';

export default {
  components: {},
  props: {
    value: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      selected_value: -1,

      entity_list: [],
    };
  },
  watch: {
    value(pNewValue, pOldValue) {
      const __this = this;
      __this.selected_value = pNewValue;
    },
    selected_value(pNewValue, pOldValue) {
      const __this = this;
      __this.$emit('input', pNewValue);
    },
  },
  methods: {
    __init_entity_list() {
      const __this = this;

      return Q.when()
        .then(function () {
          return wallet_account_queryFlowTypeEnum();
        })
        .then(function (data) {
          const queryFlowTypeResults = data.queryFlowTypeResults;
          const list = _.chain(queryFlowTypeResults)
            .map(function (m) {
              const o = extend(true, {}, m);
              o.active = false;
              return o;
            })
            .value()
          ;
          __this.entity_list = list;
        })
        .catch(function (ex) {
          console.error(ex);
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        })
        ;
    },

    __btn_entity_click(pEntity) {
      const __this = this;

      _.each(__this.entity_list, function (m) {
        m.active = false;
      });
      pEntity.active = true;

      __this.selected_value = pEntity.key;
    },
  },
  created() {
    const __this = this;

    return Q.when()
      .then(function () {
        return __this.__init_entity_list();
      })
      .then(function () {
        const query = _.chain(__this.entity_list)
          .find(function (m) {
            return m.key === __this.value;
          })
          .value()
        ;
        if (query) {
          query.active = true;
          return;
        }

        if (__this.entity_list.length > 0) {
          __this.entity_list[0].active = true;
        }
      })
      .catch(function (ex) {
        console.error(ex);
        if (ex) {
          if (ex.stack) {
            console.error(ex.stack);
          }
        }
        return Q.reject(ex);
      })
      ;
  },
  activated() {
    // const __this = this;
  },
  deactivated() {
    // const __this = this;
  },
}
