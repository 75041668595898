const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 接口文档地址：
// http://192.168.0.15:8080/doc.html#/所有接口/wallet-account-api-controller/queryFlowTypeEnumUsingPOST
const wallet_account_queryFlowTypeEnum = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/wallet/account/queryFlowTypeEnum',
    data: {}
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      const queryFlowTypeResults = data.queryFlowTypeResults;
      if (!queryFlowTypeResults) {
        const msg = 'wallet_account_queryFlowTypeEnum parameter error, code=001';
        console.error(msg);
        return Q.reject({msg: msg});
      }
      if (!_.isArray(queryFlowTypeResults)) {
        const msg = 'wallet_account_queryFlowTypeEnum parameter error, code=002';
        console.error(msg);
        return Q.reject({msg: msg});
      }

      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = wallet_account_queryFlowTypeEnum;
