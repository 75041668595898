import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';

import SelectorFlowType
  from '@/page/distributor/yinqianbao/back/account-management/component/selector-flow-type/index.vue';
import DialogFlowDetail
  from '@/page/distributor/yinqianbao/back/account-management/component/dialog-flow-detail/index.vue';
import DialogFlowNote
  from '@/page/distributor/yinqianbao/back/account-management/component/dialog-flow-note/index.vue';
import wallet_account_queryAccountDetail from '@/lib/data-service/default/wallet_account_queryAccountDetail';
import wallet_account_queryAccountFlow from '@/lib/data-service/default/wallet_account_queryAccountFlow';
import wallet_account_exportAccountFlow from '@/lib/data-service/default/wallet_account_exportAccountFlow';
import wallet_account_alipay_fundBatch_detail from "@/lib/data-service/default/wallet_account_alipay_fundBatch_detail";

export default {
  components: {
    SelectorFlowType,
    DialogFlowDetail,
    DialogFlowNote
  },
  data() {
    return {
      data_wallet_account_queryAccountDetail: {
        balanceAmount: 0,
        freezeAmount: 0,
        usableAmount: 0,
        whetherOpening: false,
        passwordStatus: 1
      },
      formData: {
        pageSize: 10,
        currentPage: 1,
        totalCount: 0
      },
      data_wallet_account_queryAccountFlowList: [],
      data_wallet_account_queryAccountFlow: {
        pageResult: {
          pageData: [],
          pageSize: 10,
          pageCount: 0,
          totalCount: 0,
        }
      },

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

      value_tradingHour: null,

      form: {
        flowType: 0,
      },

      flag_display_amount: false,
      show_recharge_btn_flag: false,
    };
  },
  computed: {
    tradingHourStart() {
      const __this = this;
      let r = ``;
      const value_tradingHour = __this.value_tradingHour;
      if (!value_tradingHour) return r;
      if (!_.isArray(value_tradingHour)) return r;
      if (value_tradingHour.length !== 2) return r;
      const date = value_tradingHour[0];
      const m = moment(date);
      if (!m.isValid()) return r;
      r = m.format(`YYYY-MM-DD 00:00:00`);
      return r;
    },
    tradingHourEnd() {
      const __this = this;
      let r = ``;
      const value_tradingHour = __this.value_tradingHour;
      if (!value_tradingHour) return r;
      if (!_.isArray(value_tradingHour)) return r;
      if (value_tradingHour.length !== 2) return r;
      const date = value_tradingHour[1];
      const m = moment(date);
      if (!m.isValid()) return r;
      r = m.format(`YYYY-MM-DD 23:59:59`);
      return r;
    },

    usableAmount() {
      const __this = this;
      let r = `******`;
      if (__this.flag_display_amount) {
        r = __this.data_wallet_account_queryAccountDetail.usableAmountText;
      }
      return r;
    },
    balanceAmount() {
      const __this = this;
      let r = `******`;
      if (__this.flag_display_amount) {
        r = __this.data_wallet_account_queryAccountDetail.balanceAmountText;
      }
      return r;
    },
    freezeAmount() {
      const __this = this;
      let r = `******`;
      if (__this.flag_display_amount) {
        r = __this.data_wallet_account_queryAccountDetail.freezeAmountText;
      }
      return r;
    },

    btn_display_amount() {
      const __this = this;
      let r = ``;
      if (__this.flag_display_amount) {
        r = `隐藏金额`;
      } else {
        r = `显示金额`;
      }
      return r;
    },
  },
  watch: {
    value_tradingHour(pNewValue, pOldValue) {
      const __this = this;
      // __this.__init_list();
    },
    'form.flowType'(pNewValue, pOldValue) {
      const __this = this;
      // __this.__init_list();
    },
  },
  methods: {
    exprotExcel() {
      if (this.value_tradingHour) {
        let endTime = new Date(this.value_tradingHour[1]).valueOf()
        let threeMonths = new Date(moment(this.value_tradingHour[0]).add(6, 'months')).valueOf()
        if (endTime > threeMonths) return this.$message({ type: 'warning', message: '只能导出6个月内的交易记录!' })
      }

      let data = {
        flowType: this.form.flowType,
        tradingHourStart: this.tradingHourStart,
        tradingHourEnd: this.tradingHourEnd
      }
      wallet_account_exportAccountFlow(data).then(res => {
        let tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = `http://${res.url}`;
        tempLink.setAttribute("download", '');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      })
    },
    __btn_date_click(pType) {
      const __this = this;
      const now = moment();

      let end, start;
      switch (pType) {
        case `000`:
          end = moment(now.format(`YYYY-MM-DD 23:59:59`)).toDate();
          start = moment(now.format(`YYYY-MM-DD 00:00:00`)).toDate();
          break;

        case `100`:
          end = moment(now.format(`YYYY-MM-DD 23:59:59`)).toDate();
          start = moment(now.add(-1, `M`).format(`YYYY-MM-DD 00:00:00`)).toDate();
          break;

        case `200`:
          end = moment(now.format(`YYYY-MM-DD 23:59:59`)).toDate();
          start = moment(now.add(-3, `M`).format(`YYYY-MM-DD 00:00:00`)).toDate();
          break;
      }

      __this.value_tradingHour = [
        start,
        end,
      ];
    },

    __init_list() {
      const __this = this;
      return Q.when()
        .then(function () {
          const p = {};
          p.flowType = __this.form.flowType;
          p.tradingHourStart = __this.tradingHourStart;
          p.tradingHourEnd = __this.tradingHourEnd;
          p.currentPage = __this.formData.currentPage;
          p.pageSize = __this.formData.pageSize;

          return wallet_account_queryAccountFlow(p);
        })
        .then(function (data) {
          __this.formData.totalCount = data.pageResult.totalCount
          __this.data_wallet_account_queryAccountFlowList = data.pageResult.pageData
        })
        .catch(function (ex) {
          if (ex) {
            if (ex.stack) {
            }
          }
          return Q.reject(ex);
        })
        ;
    },

    __btn_display_amount_click() {
      const __this = this;
      __this.flag_display_amount = !__this.flag_display_amount;
    },

    __btn_flow_detail_click(pEntity) {
      const __this = this;
      __this.$refs.aDialogFlowDetail.show({
        entity: pEntity,
      });
    },

    __btn_flow_note_click(pEntity) {

      const __this = this;
      __this.$refs.aDialogFlowNote.show({
        entity: pEntity,

      });
    },

    go_init_password() {
      this.$router.push({
        name: 'distributor-yinqianbao-back-account-safety'
      })
    },

    __el_pagination_size_change_event_handler(val) {
      this.formData.pageSize = val
      this.__init_list()
    },

    __el_pagination_current_change_event_handler(pCurrentPage) {
      this.formData.currentPage = pCurrentPage
      this.__init_list()
    },

    // 授权详情
    async checkAlipayCompanyAccount() {
      wallet_account_alipay_fundBatch_detail({}).then(res => {
        //是否显示转账充值按钮
        if (res.accountResult.accountNum > 0) {
          this.show_recharge_btn_flag = true;
        } else {
          this.show_recharge_btn_flag = false;
        }
      }).catch(err => {
      })
    },

    //充值
    async recharge(val) {
      // 判断授权码是否有效
      wallet_account_alipay_fundBatch_detail({}).then(res => {
        if (res.accountResult.accountNum > 0) {
          this.show_recharge_btn_flag = true;
          this.$router.push({
            name: `distributor-account-alipay-recharge`,
          });
        } else {
          this.$message.error('操作失败，暂无可付款账号')
          this.show_recharge_btn_flag = false;
          this.__init_list();
        }
      }).catch(err => {
      })
    },
  },
  activated() {
    const __this = this;
    __this.__init_list();
    wallet_account_queryAccountDetail().then(function (data) {
      __this.data_wallet_account_queryAccountDetail = data;
    });
    __this.checkAlipayCompanyAccount();
  },
  mounted() {
    const __this = this;
  },

  deactivated() {
    // const __this = this;
  },
}
