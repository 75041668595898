import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import wallet_account_queryFlowDetail from '@/lib/data-service/default/wallet_account_queryFlowDetail';

export default {
  components: {},
  data() {
    return {

      dialog: {
        show: false,
      },

      entity: {
        flowType: 0,
      },
    };
  },
  watch: {},
  methods: {
    show(pParameter) {
      const __this = this;

      if (!pParameter) pParameter = {};
      const entity = pParameter.entity;
      const flowNo = entity.flowNo;
      const flowType = entity.flowType;
      return Q.when()
        .then(function () {
          return wallet_account_queryFlowDetail({
            flowNo,
            flowType,
          })
        })
        .then(function (data) {
          __this.entity = data;
          __this.dialog.show = true;
        })
        .catch(function (ex) {
          console.error(ex);
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        })
        ;

    },
  },
  created() {
    const __this = this;
    return;

    // eslint-disable-next-line no-unreachable
    return Q.when()
      .then(function () {
        return __this.__init_entity();
      })
      .then(function () {
        const query = _.chain(__this.entity_list)
          .find(function (m) {
            return m.key === __this.value;
          })
          .value()
        ;
        if (query) {
          query.active = true;
          return;
        }

        if (__this.entity_list.length > 0) {
          __this.entity_list[0].active = true;
        }
      })
      .catch(function (ex) {
        console.error(ex);
        if (ex) {
          if (ex.stack) {
            console.error(ex.stack);
          }
        }
        return Q.reject(ex);
      })
      ;
  },
  activated() {
    // const __this = this;
  },
  deactivated() {
    // const __this = this;
  },
  mounted () {
    
  }
}
