const __request = require(`./__request/__request_contentType_json`)

const wallet_account_alipay_fundBatch_detail = (pParameter) => {
    if (!pParameter) pParameter = {};
    pParameter = {
        method: 'post',
        urlSuffix:"/wallet/account/alipay/fundBatch/detail",
        data: pParameter
    }

    return __request(pParameter)
}
module.exports = wallet_account_alipay_fundBatch_detail;