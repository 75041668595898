const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 接口文档地址：
// http://192.168.0.15:8080/doc.html#/所有接口/wallet-account-controller/queryAccountDetailUsingPOST
// http://192.168.0.15:9988/swagger-ui.html#/wallet-account-controller/queryAccountDetailUsingPOST
const wallet_account_queryFlowDetail = (pParameter) => {
  if (!pParameter) pParameter = {};
  const flowNo = pParameter.flowNo || ``;
  const flowType = pParameter.flowType || ``;
  const p = {
    method: 'post',
    urlSuffix: '/wallet/account/queryFlowDetail',
    data: {
      flowNo,
      flowType,
    }
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      const flowNo = data.flowNo;
      if (!flowNo) {
        const msg = 'wallet_account_queryFlowDetail parameter error, code=101';
        console.error(msg);
        return Q.reject({msg: msg});
      }

      const flowType = data.flowType;
      if (!flowType) {
        const msg = 'wallet_account_queryFlowDetail parameter error, code=102';
        console.error(msg);
        return Q.reject({msg: msg});
      }

      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = wallet_account_queryFlowDetail;
