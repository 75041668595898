import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import wallet_account_updateFlowRemark from '@/lib/data-service/default/wallet_account_updateFlowRemark';

export default {
  components: {},
  data() {
    return {

      dialog: {
        show: false,
      },

      entity: {
        id: 0,
        remark: '',
      },

      remark: '',
      id: '',
      dialogVisible: false,
    };
  },
  props: {
    __init_list: Function,
  },
  watch: {},
  methods: {
    show(pParameter) {
      const __this = this;
      this.dialogVisible = true
      if (!pParameter) pParameter = {};
      __this.entity = pParameter.entity;
      __this.remark = __this.entity.remark;
      __this.id = __this.entity.id;

    },

    reqChangeRemark(query) {
      const _this = this;
      Q.when()
        .then(function () {
          return wallet_account_updateFlowRemark(query)
        })
        .then(function (res) {
          _this.$message({
            showClose: true,
            message: '备注修改成功',
            type: 'success'
          });
          _this.dialogVisible = false;
          _this.__init_list();
        })
    },

    handleCommit() {
      if (!this.remark || this.remark === this.entity.remark) {
        this.dialogVisible = false;
        return;
      }

      const query = { remark: this.remark, id: this.id };
      this.reqChangeRemark(query)
    }
  },
  created() {
    // 
  },
  activated() {
    // const __this = this;
  },
  deactivated() {
    // const __this = this;
  },
}
